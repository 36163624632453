import { JUSYOU_REKI } from "~/constants/contest";
import { SEKOU_COUNT } from "~/constants/sekou";
export const contents = [
  {
    title: "施工例公開中",
    subtitle: `${addComma(SEKOU_COUNT)}件以上`,
    alt: "外構・リフォーム・エクステリア工事の施工例",
    path: "/images/contents/sekou.png",
    link: "/sekou",
  },
  {
    title: "プラン事例",
    subtitle: `外構費用の参考に`,
    alt: "外構・リフォーム工事プランの事例を紹介",
    path: "/images/contents/plan.png",
    link: "/plan",
  },
  {
    title: "お客様の声",
    subtitle: "すべて見せます",
    alt: "外構・リフォーム・エクステリア工事をしたお客様の声",
    path: "/images/contents/review.png",
    link: "/e_list",
  },
  {
    title: "コンテスト受賞作品",
    subtitle: `${JUSYOU_REKI}年連続受賞!`,
    alt: "外構・エクステリアコンテスト受賞作品",
    path: "/images/contents/contest.png",
    link: "/contents.php/contest",
  },

  {
    title: "工事単価公開!",
    subtitle: "自信あり!比べて下さい!",
    alt: "外構・リフォーム・エクステリア工事単価を公開",
    path: "/images/contents/price.png",
    link: "/contents.php/kouji",
  },
  {
    title: "工事の流れ",
    subtitle: "工事完了まで社員が担当します",
    alt: "外構・リフォーム工事の流れ",
    path: "/images/contents/flow.png",
    link: "/contents.php/flow",
  },
  {
    title: "お支払い方法",
    subtitle: "お得なローンもご利用できます",
    alt: "外構工事のお支払いについて",
    path: "/images/contents/payment.png",
    link: "/contents.php/payment",
  },
  {
    title: "アフターフォロー",
    subtitle: "工事後も安心",
    alt: "外構・リフォーム・エクステリア工事のアフターフォロー",
    path: "/images/contents/after.png",
    link: "/contents.php/after",
  },
  {
    title: "お得情報ブログ",
    subtitle: "役立つ情報発信中",
    alt: "外構・リフォーム工事に役立つ情報を発信",
    path: "/images/contents/blog.png",
    link: "/BLOG/Finfo",
  },
  {
    title: "よくあるご質問",
    subtitle: "すべてお答えします",
    alt: "外構・リフォーム工事でよくある質問",
    path: "/images/contents/Q&A.png",
    link: "/contents.php/qa",
  },
];
