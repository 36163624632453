<script setup lang="ts">
// リンク作成用データ
import { companies } from "~/constants/top/companies";
import { contents } from "~/constants/top/contents";
import { shoplist } from "~/constants/top/shoplist";
import { isCloudflare } from "~/utils";
import type { Instagram } from "~/types";

definePageMeta({ layout: "no-bread-crumbs" });

// インスタグラム表示
const instagram = ref<Instagram>([]);

const instaRef = ref(null);
const { stop } = useIntersectionObserver(
  instaRef,
  async ([{ isIntersecting }]) => {
    if (isIntersecting) {
      instagram.value = await $fetch("/api/instagram");
      stop();
    }
  },
);

/**
 * メタ施工例カウント
 */
const attr = await useSekouAttr();
const metaCount = {
  open: 0,
  carport: 0,
  hei: 0,
};
if (attr.value && attr.value.length) {
  attr.value?.forEach((a) => {
    if (a.attr_num == 1) {
      metaCount.open = a.count;
    } else if (a.attr_num == 107) {
      metaCount.hei = a.count;
    } else if (a.category == 6 && a.cate_name.match(/カーポート/)) {
      metaCount.carport += a.count;
    }
  });
}

useHead({
  title: "激安外構庭リフォーム工事",
  meta: [
    {
      name: "description",
      content:
        "見積無料・10年保証付で工事後も安心。" +
        `おしゃれオープン外構${metaCount.open}件・激安カーポート${metaCount.carport}件・目隠し塀${metaCount.hei}件公開。` +
        "関西・中京・関東・九州エリア/全国送料無料ネット通販",
    },
  ],
});

if (!isCloudflare()) {
  try {
    // @ts-ignore
    defineOgImage({
      component: "Base",
    });
  } catch (e) {
    console.log(e);
  }
}
</script>

<template>
  <div>
    <!-- notice -->
    <NewsNotice class="wrapper my-5" />
    <!-- banner -->
    <SwiperTopBanner />

    <div class="wrapper-nopadding">
      <SlideTopRecommend class="mb-8 tablet:mb-16" />
      <div class="px-2 mb-8 tablet:mb-16">
        <SekouMenuKeywordSearch />
      </div>
      <SekouAreaPrefecture></SekouAreaPrefecture>
    </div>

    <section>
      <!-- content -->
      <TextMinchoTitle>
        <span>Content</span>
        <span class="p-2 text-sm">コンテンツ</span>
      </TextMinchoTitle>
      <div class="p-contents wrapper mb-8 tablet:mb-16">
        <ListResponsiveWrapper :sp="2" class="mb-8 tablet:mb-16">
          <ListResponsiveItem v-for="ct in contents" :key="ct.title">
            <NuxtLink class="text-center" :to="ct.link">
              <ImageEl
                class="w-full"
                :src="ct.path"
                :alt="ct.alt"
                width="500"
                height="250"
                sizes="200px md:400px"
                loading="lazy"
                format="webp"
              />
              <div class="py-2">
                <p class="text-xs line-clamp-1">{{ ct.subtitle }}</p>
                <p class="font-bold tablet:text-lg line-clamp-1">
                  {{ ct.title }}
                </p>
              </div>
            </NuxtLink>
          </ListResponsiveItem>
        </ListResponsiveWrapper>
        <ListResponsiveWrapper :tablet="3" class="bg-gray-100 p-4 tablet:p-8">
          <ListResponsiveItem
            :border="false"
            v-for="shop in shoplist"
            :key="shop.title"
          >
            <NuxtLink class="text-center" :href="shop.link">
              <p class="text-sm line-clamp-1">{{ shop.title }}</p>
              <ImageEl
                class="w-full mx-auto"
                :src="shop.path"
                :alt="shop.alt"
                width="900"
                height="450"
                sizes="300px lg:400px"
                loading="lazy"
                format="webp"
              />
              <p class="font-bold line-clamp-1">{{ shop.name }}</p>
            </NuxtLink>
          </ListResponsiveItem>
        </ListResponsiveWrapper>
      </div>
    </section>

    <section>
      <div class="bg-green-50 py-4 mb-8 tablet:mb-16">
        <!-- company -->
        <TextMinchoTitle>
          <span>Company</span>
          <span class="p-2 text-sm">企業情報</span>
        </TextMinchoTitle>
        <div class="p-contents wrapper">
          <ListResponsiveWrapper :sp="2">
            <ListResponsiveItem v-for="cp in companies" :key="cp.title">
              <NuxtLink class="text-center" :to="cp.link">
                <ImageEl
                  class="w-full"
                  :src="cp.path"
                  :alt="cp.alt"
                  width="500"
                  height="250"
                  sizes="300px md:400px"
                  loading="lazy"
                  format="webp"
                />
                <div class="py-2 flex-grow">
                  <p class="text-xs h-4 line-clamp-1">{{ cp.subtitle }}</p>
                  <p class="font-bold tablet:text-lg line-clamp-1">
                    {{ cp.title }}
                  </p>
                </div>
              </NuxtLink>
            </ListResponsiveItem>
          </ListResponsiveWrapper>
        </div>
      </div>
    </section>

    <div class="wrapper flex gap-12 justify-center mb-8 tablet:mb-16">
      <NuxtLink
        to="https://www.instagram.com/ex.komikomi/"
        aria-label="ファミリー庭園公式Instagram"
      >
        <Icon size="52" name="skill-icons:instagram" />
      </NuxtLink>
      <NuxtLink
        to="https://twitter.com/family_teien"
        aria-label="ファミリー庭園公式Twitter"
      >
        <Icon size="52" name="ri:twitter-x-line" />
      </NuxtLink>
      <NuxtLink
        to="https://www.pinterest.jp/ex_komikomi/"
        aria-label="ファミリー庭園公式Pinterest"
      >
        <Icon size="52" name="logos:pinterest" />
      </NuxtLink>
    </div>

    <!-- instagram tailwindcssで3列の表示スタイルを書く -->
    <div class="py-4 mb-8 tablet:mb-16" ref="instaRef">
      <!-- company -->
      <TextMinchoTitle>
        <span>Instagram</span>
        <span class="p-2 text-sm">公式インスタグラム</span>
      </TextMinchoTitle>

      <div
        class="wrapper flex gap-12 justify-center mb-8 tablet:mb-16 relative"
      >
        <ul v-if="instagram && instagram.length" class="grid grid-cols-3 gap-4">
          <li
            v-for="item in instagram"
            :key="item.id"
            class="border border-gray-200 rounded-lg"
          >
            <NuxtLink :to="item.permalink">
              <ImageEl
                width="600"
                height="600"
                provider="customimgproxy"
                class="w-full rounded"
                :src="item.thumbnail_url ? item.thumbnail_url : item.media_url"
                :loading="'lazy'"
                :loadingAnimation="true"
                format="webp"
                resize="fill"
                gravity="ce"
                enlarge="1"
              />
            </NuxtLink>
          </li>
        </ul>
        <Loading
          v-else
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </div>
    </div>

    <!-- contact -->
    <TopContactBanner />

    <!-- calendar -->
    <section>
      <div class="items-center pc:flex wrapper mb-16">
        <div class="pc:w-1/2 mx-auto">
          <h2 class="text-lg font-bol w-fit mx-auto mb-2">営業カレンダー</h2>
          <CalendarMain />
        </div>
      </div>
    </section>
  </div>
</template>
