export const shoplist = [
  {
    title: "工事付き！エクステリア",
    name: "工事コミコミショップ",
    alt: "ファミリー庭園工事コミコミショップ",
    path: "/images/contents/komikomi.png",
    link: "https://famitei.mobi/",
  },
  {
    title: "送料無料！激安エクステリア販売",
    name: "ネットショップ 全国物販",
    alt: "ファミリー庭園ネットショップ",
    path: "/images/contents/netshop.png",
    link: "https://www.rakuten.ne.jp/gold/famitei/",
  },
  {
    title: "関西最安値！工事付きコミコミ価格",
    name: "水まわりショップ",
    alt: "ファミリー庭園水まわり工事",
    path: "/images/contents/mizumawari.png",
    link: "https://rehome.mobi/",
  },
];
