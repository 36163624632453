import { SEKOU_COUNT } from "~/constants/sekou";
import { JUSYOU_REKI } from "~/constants/contest";
import { REVIEW_COUNT } from "~/constants/review";
import type { TopSlideBanner } from "~/types";

export const sekou: TopSlideBanner = {
  alt: "関西でNo1外構施工例実績。外構・リフォーム工事施工例を毎日更新中",
  text: {
    type: "sekou",
    value: addComma(SEKOU_COUNT),
    position: { x: 20, y: 74 },
  },
  path: "/images/banner/sekou.png",
  link: "/sekou",
  preload: true,
};

export const plan: TopSlideBanner = {
  alt: "外構・リフォーム工事プラン事例を公開中",
  path: "/images/banner/plan.png",
  link: "/plan",
};

export const set_discounts: TopSlideBanner = {
  alt: "外構・リフォーム工事をお得に。同時購入がお得なセット割",
  path: "/images/banner/set.png",
  link: "https://famitei.mobi/contents.php/set_discounts",
};

export const contest: TopSlideBanner = {
  alt: `外構・エクステリアコンテスト${JUSYOU_REKI}年連続受賞中`,
  text: {
    type: "contest",
    value: `${JUSYOU_REKI}年連続受賞中`,
    position: { x: 21, y: 22 },
  },
  path: "/images/banner/contest.png",
  link: "/contents.php/contest",
};

export const review: TopSlideBanner = {
  alt: "外構・リフォーム工事をしたお客様の声を公開しています！",
  text: {
    type: "review",
    value: addComma(REVIEW_COUNT),
    position: { x: 32, y: 74 },
  },
  path: "/images/banner/review.png",
  link: "/e_list",
};

export const mobi: TopSlideBanner = {
  alt: "安心工事付き 工事コミコミショップ",
  path: "/images/banner/mobi.png",
  link: "https://famitei.mobi/",
};

export const campaign: TopSlideBanner = {
  alt: "ご成約感謝キャンペーン",
  path: "/images/banner/seiyakucp.png",
  link: "https://www.famitei.co.jp/contents.php/present/",
};

export const mizu: TopSlideBanner = {
  alt: "関西限定 水まわり工事",
  path: "/images/banner/mizu.png",
  link: "https://rehome.mobi/",
  preload: true,
};
