import { SOGYOU } from "~/constants/contest";

export const companies = [
  {
    title: "会社概要",
    subtitle: `創業${SOGYOU}年`,
    alt: "ファミリー庭園会社概要",
    path: "/images/company/about.png",
    link: "/contents.php/profile",
  },
  {
    title: "ファミリー庭園とは",
    subtitle: "ファミテイのすべて",
    alt: "ファミリー庭園のすべて",
    path: "/images/company/what.png",
    link: "/contents.php/what_famitei",
  },
  {
    title: "店舗一覧",
    subtitle: "全国14店舗",
    alt: "外構工事ができる店舗一覧",
    path: "/images/company/shops.png",
    link: "/contents.php/shop",
  },
  {
    title: "外構工事がおトク!",
    subtitle: "ファミテイが選ばれる理由!",
    alt: "ファミリー庭園の外構工事がおトクな理由",
    path: "/images/company/reason.png",
    link: "/contents.php/otoku",
  },
  {
    title: "プライバシーポリシー",
    subtitle: "",
    alt: "ファミリー庭園のプライバシーポリシー",
    path: "/images/company/policy.png",
    link: "/contents.php/privacy",
  },
];
