<script setup lang="ts">
const isShow = ref(false);

function showButton() {
  if (100 <= window.scrollY) {
    isShow.value = true;
  } else {
    isShow.value = false;
  }
}

onMounted(() => {
  window.addEventListener("scroll", showButton, { passive: true });
});
</script>

<template>
  <p class="text-sm pc:text-xl tablet:text-lg w-fit mx-auto">
    外構工事、エクステリア工事について
  </p>
  <p class="text-sm pc:text-xl tablet:text-lg w-fit mx-auto">
    お気軽にご相談・お問い合わせください
  </p>
  <div
    v-show="isShow"
    class="text-center sticky tablet:relative bg-white bottom-0 bg-opacity-80 p-4"
    :class="{ '': isShow }"
  >
    <NuxtLink
      to="/inquiry/form"
      class="block bg-[#338438] hover:bg-[#2e7833] drop-shadow text-white rounded p-2 my-4 tablet:w-2/3 mx-auto"
    >
      <p class="text-lg">24時間受付中</p>
      <p class="text-xl">資料請求・お問い合わせフォーム</p>
    </NuxtLink>
  </div>
  <TopContactTelLink />
</template>
