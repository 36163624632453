<script setup lang="ts">
import { SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";

const modules = [Pagination, Autoplay];

const slideBanners = useTopSlide();
const isShow = ref(false);
function onInit() {
  isShow.value = true;
}
</script>

<template>
  <div
    class="top-banner relative w-full aspect-[3/2] tablet:aspect-[3/1] overflow-hidden wrapper-nopadding tablet:mt-8 mb-4"
  >
    <div :style="{ visibility: isShow ? 'visible' : 'hidden' }">
      <ClientOnly>
        <SwiperBase
          @init="onInit"
          :modules="modules"
          :slides-per-view="3"
          :breakpoints="{
            '0': {
              slidesPerView: 1,
            },
            '600': {
              slidesPerView: 2,
            },
          }"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
          :pagination="{
            clickable: true,
          }"
          :loop="true"
          :centered-slides="true"
          effect="slide"
        >
          <SwiperSlide
            class="w-full tablet:w-1/2 max-w-[640px] tablet:brightness-50 relative"
            v-for="(d, idx) in slideBanners"
            :key="idx"
            :lazy="!d.preload"
          >
            <NuxtLink class="gtm-event-top_banner_item" :to="d.link">
              <ImageEl
                class="w-full select-none cursor-grab"
                :src="d.path"
                :alt="d.alt"
                width="750"
                height="500"
                sizes="375px md:500px lg:605px"
                :loading="!d.preload ? 'lazy' : 'eager'"
                format="webp"
                :preload="d.preload"
              />
              <TextTopBanner v-if="d.text" :text="d.text" />
            </NuxtLink>
          </SwiperSlide>
        </SwiperBase>
      </ClientOnly>
    </div>
    <Loading
      v-if="!isShow"
      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    />
  </div>
</template>

<style scoped>
.top-banner {
  /** スライドの遷移時に、filterとtransformをかける*/
  /** transfromは、スケールに対して、フィルターはドラッグ時*/
  .swiper-slide {
    transition-property: filter, transform;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;

    /** 中央のスライドのみ明るくする*/
    &.swiper-slide-active {
      @apply brightness-100 z-10;
    }

    /** PC:前後のスライドのサイズ縮小*/
    &.swiper-slide-next,
    &.swiper-slide-prev {
      @apply tablet:scale-75;
    }
  }
}
</style>
