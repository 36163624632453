import type { TopSlideBanner } from "~/types";
import {
  sekou,
  plan,
  set_discounts,
  contest,
  review,
  mobi,
  campaign,
  mizu,
} from "@/constants/top/slideBanner";
export default function () {
  const slides: TopSlideBanner[] = [
    sekou,
    plan,
    set_discounts,
    contest,
    review,
    mobi,
    campaign,
    mizu,
  ];

  return slides;
}
